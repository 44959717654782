/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-link class="brand-logo">

        <!-- <vuexy-logo /> -->
        <b-img
          :src="appLogoImage"
          fluid
          alt="Logo Rayo"
          width="40px"
        />
        <h2 class="brand-text text-primary ml-1">
          Rayo Partners 2x
        </h2>
      </b-link>
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Bienvenid@ a Soporte Rayo 👋
          </b-card-title>
          <b-card-text class="mb-2">
            ¿Cómo podemos ayudarte?
          </b-card-text>

          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Plataforma de Administración</span></small>
              </p>
              <!-- <p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
              </p> -->
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'Una plataforma desarrollada por Rayo Latam'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert>

          <!-- form -->
          <!-- <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >

              <b-link :to="{name:'auth-forgot-password'}" />

              <b-button
                type="submit"
                :disabled="invalid"
                block
                variant="primary"
              >
                <feather-icon
                  icon="LogInIcon"
                  class="mr-50"
                />
                Login
              </b-button>

            </b-form>
          </validation-observer> -->

          <b-card-text class="text-center mt-2">
            <!-- <span>Eres nuev@ en la plataforma? Pronto podrás crear tu cuenta</span> -->
            <b-button :to="{name:'pages-faq'}">
              <span>Ingresar</span>
            </b-button>
          </b-card-text>

          <!-- divider -->
          <div
            style="padding: 4px;"
            class="divider my-2"
          >
            <span style="margin: 4px;">{{ version }}</span>

            <!-- social buttons -->
            <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
          </div></b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */

import { ValidationObserver } from 'vee-validate'
// import axios from 'axios'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink,
  //  BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox,
  BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
// import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
// eslint-disable-next-line no-unused-vars
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line no-unused-vars
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
// import * as Realm from 'realm-web'
import userStoreModule from '../../apps/postulaciones/userStoreModule'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    // BFormGroup,
    // BFormInput,
    // BInputGroupAppend,
    // BInputGroup,
    // BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BAlert,
    // VuexyLogo,
    // ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    const USER_APP_STORE_MODULE_NAME = 'app-postulaciones'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    return {
      appName,
      appLogoImage,
    }
  },
  data() {
    return {
      version: 0.00000641,
      status: '',
      // apiUrl: 'https://us-central1-entregasrayo.cloudfunctions.net/serverPartners',
      // DESARROLLO LOCALHOST:8081
      // apiUrl: 'http://localhost:8081',
      // apiUrl: store.state.appConfig.layout.apiUrl,
      password: 'admin',
      userEmail: 'admin@demo.com',
      // sideImg: require('@/assets/images/pages/login-v2.svg'),
      sideImg: require('@/assets/images/pages/kid1.png'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/logo.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
